import React from "react";

const InstagramAccount = ({account, handleAccountClick}) => {
    const {instagram_business_account, name, id} = account;

    return (
        <div onClick={() => handleAccountClick(instagram_business_account.id)}
             className="cursor-pointer flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-md">
            <img src={instagram_business_account.profile_picture_url} alt={instagram_business_account.username}
                 className="w-20 h-20 rounded-full mb-2"/>
            <div className="text-center">
                <span className="text-lg font-semibold">@{instagram_business_account.username}</span>
                <p className="text-sm text-gray-500">{instagram_business_account.followers_count} followers</p>
                <p className="text-sm text-gray-500">{name}</p>
                <p className="text-sm text-gray-500">Page ID: {id}</p>
            </div>
        </div>
    );
}

export default InstagramAccount;