"use client"

import * as React from "react"
import {Check, ChevronsUpDown} from "lucide-react"

import {cn} from "../../lib/utils"
import {Button} from "../ui/button"
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/command";


export function LanguageSelection({
                                      languages,
                                      onLanguageSelected,
                                      selectedLanguage,
                                  }) {
    const [open, setOpen] = React.useState(false)


    const onOpenChange = (nextOpen) => {
        setOpen(nextOpen);
    }


    return (
        <Popover open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[300px] justify-between notranslate" translate="no"
                >
                    {selectedLanguage.name}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[300px] p-0 h-[300px]" onOpenAutoFocus={(e) => e.preventDefault()}>
                <Command className="w-full">
                    <CommandInput
                        placeholder={"Search language..."}
                        className="focus:ring-0 focus:border-transparent border-none"/>
                    <CommandEmpty>No language found.</CommandEmpty>
                    <CommandList className="notranslate" translate="no">
                        <CommandGroup>
                            {languages.map((language) => (
                                <CommandItem
                                    key={language.name}
                                    value={language.name}
                                    className={"cursor-pointer"}
                                    onSelect={(currentValue) => {
                                        const selectedLanguage = languages.find((language) => language.name === currentValue);
                                        onLanguageSelected(selectedLanguage);
                                        setOpen(false);
                                    }}
                                >

                                    <div
                                        className="flex flex-row justify-between items-center w-full min-w-full">
                                        <div className="flex flex-row cursor-pointer items-center">
                                            <Check
                                                className={cn(
                                                    "mr-2 h-4 w-4",
                                                    selectedLanguage?.id === language.id ? "opacity-100" : "opacity-0"
                                                )}
                                            />
                                            <div className="">{language.name}</div>
                                        </div>
                                    </div>
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
