import React, {useState} from "react"
import PropTypes from "prop-types"
import PersonaItem from "./PersonaItem"
import PrimaryButton from "../common/PrimaryButton";
import {showJetToast} from "../Toast";
import InstagramConnect from "./InstagramConnect";
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../ui/select";

const CreateBrand = ({personas, root_url, brand, brandPersona, actionName}) => {
    const [selectedPersona, setSelectedPersona] = useState(brandPersona || personas[0]);
    const [brandTitle, setBrandTitle] = useState(brand?.title);
    const [brandDescription, setBrandDescription] = useState(brand?.description);
    const [isLoading, setIsLoading] = useState(false);
    const [showInstagramConnect, setShowInstagramConnect] = useState(false);
    const [visibility, setVisibility] = useState( brand?.visibility || 'public' );
    const [brandId, setBrandId] = useState(brand?.id);

    const handlePersonaSelection = (persona) => {
        if (selectedPersona?.title === persona.title) {
            setSelectedPersona(null);
            return;
        }
        setSelectedPersona(persona);
    }

    const isEnabled = selectedPersona && brandTitle && brandTitle.length > 0;

    const updateBrand = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`/pages/${brandId}.json`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    brand: {
                        title: brandTitle,
                        description: brandDescription,
                        persona_id: selectedPersona.id,
                        visibility: visibility
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const brand = await response.json();
            console.log('Page updated');
            showJetToast('Page updated successfully');
            window.location.href = '/settings';
        } catch (error) {
            console.error('Error:', error);
            showJetToast('Error updating page. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }

    const createBrand = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('/pages', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    brand: {
                        title: brandTitle,
                        description: brandDescription,
                        persona_id: selectedPersona.id,
                        visibility: visibility
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const brand = await response.json();
            setBrandId(brand.id);
            console.log('Page created');
            setShowInstagramConnect(true);
            showJetToast('Page created successfully');
        } catch (error) {
            console.error('Error:', error);
            showJetToast('Error creating page. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }

    if (showInstagramConnect) {
        return <InstagramConnect callback_url={`${root_url}instagram/callback`} brandId={brandId}/>
    }

    return (
        <div className="mt-8 shadow rounded-lg bg-white py-4 px-4">
            {actionName == "new" ? <h1 className="font-bold text-4xl">Create your page</h1> : <h1 className="font-bold text-4xl">Edit your page</h1>}
            <div className="font-medium text-xl mt-8">What best describes your page?</div>
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-4">
                {personas.map((persona, index) => (
                    <PersonaItem persona={persona} key={index}
                                 selected={selectedPersona?.title === persona.title}
                                 onClick={() => handlePersonaSelection(persona)}/>
                ))}
            </div>
            <div className="my-5">
                <label htmlFor="brand_title">Page Name</label>

                <input className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                       type="text" name="brand[title]" id="brand_title" value={brandTitle}
                       placeholder={selectedPersona?.brand_title_placeholder}
                       onChange={(e) => setBrandTitle(e.target.value)}/>
            </div>
            <div className="my-5">
                <label htmlFor="brand_description">Description</label>
                <textarea rows={4}
                          value={brandDescription}
                          placeholder={selectedPersona?.brand_description_placeholder}
                          onChange={(e) => setBrandDescription(e.target.value)}
                          className=" block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                          name="brand[description]" id="brand_description"></textarea>
            </div>
            <div>Select Visibility for content on this page. This will be used for different social platforms that support setting visibility of content when posted.</div>
            <div className="my-4">
                <Select
                    value={visibility}
                    onValueChange={(value) => setVisibility(value)}>
                    <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select Content Visibility"/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>Content Visibility on Social Platforms</SelectLabel>
                                <SelectItem key={"public"} value={"public"}><span>public</span></SelectItem>
                                <SelectItem key={"private"} value={"private"}><span>private</span></SelectItem>
                            <SelectItem key={"unlisted"} value={"unlisted"}><span>unlisted</span></SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            {actionName == "new" ?
                <PrimaryButton text="Create Page" disabled={!isEnabled} isLoading={isLoading} onClick={createBrand}/> :
                <PrimaryButton text="Update Page" disabled={!isEnabled} isLoading={isLoading} onClick={updateBrand}/>
            }
        </div>
    )
}

CreateBrand.propTypes = {
    personas: PropTypes.array.isRequired,
    root_url: PropTypes.string.isRequired,
    brand: PropTypes.object,
    brandPersona: PropTypes.object,
    actionName: PropTypes.string.isRequired
};

export default CreateBrand
