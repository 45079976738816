import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Tabs, TabsContent} from "../ui/tabs";
import ImageStyles from "./ImageStyles";
import imageStylesData from "./image_styles.json";
import audioStylesData from "./audio_styles.json";
import backgroundMusicData from "./background_music.json";
import textStylesData from "./text_styles.json";
import {showJetToast} from "../Toast";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "../ui/accordion";
import PrimaryButton from "../common/PrimaryButton";
import GhostButton from "../common/GhostButton";
import TextareaAutosize from 'react-textarea-autosize';
import TextStyles from "./TextStyles";
import {Turbo} from '@hotwired/turbo-rails';
import {AudioSelection} from "../common/AudioSelection";
import {Slider} from "../ui/slider";
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../ui/select";
import {Info} from "lucide-react";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Checkbox} from "../ui/checkbox";
import {LanguageSelection} from "../common/LanguageSelection";
import DisabledForFreeUsersModal from "../pricing/DisabledForFreeUsersModal";


const CreateShort = ({short, actionName, scriptPrompts, lastGenerationData, isPaidUser, languages}) => {
    const LAST_USED_TEXT_STYLE = "Last Used";
    const [disableScript, setDisableScript] = useState(!(actionName == 'new' || actionName !== 'create'));
    const [script, setScript] = useState(short.script || '');
    const [myScriptPrompts, setMyScriptPrompts] = useState(scriptPrompts);
    const [selectedPrompt, setSelectedPrompt] = useState(myScriptPrompts[0]);
    const [promptTopic, setPromptTopic] = useState('');
    const [audioStyles, setAudioStyles] = useState(audioStylesData);
    const [selectedImageStyle, setSelectedImageStyle] =
        useState(short.generation_data.image_settings || imageStylesData[0]);
    const [selectedAudioStyle, setSelectedAudioStyle] =
        useState(short.generation_data.voice_settings || audioStylesData[0]);

    if (lastGenerationData?.text_settings && textStylesData.filter(style => style?.visible_name == LAST_USED_TEXT_STYLE).length === 0) {
        lastGenerationData.text_settings.id = -1;
        lastGenerationData.text_settings.visible_name = LAST_USED_TEXT_STYLE;
        textStylesData.unshift(lastGenerationData.text_settings);
    }

    const [selectedBackgroundMusic, setSelectedBackgroundMusic] =
        useState(short.generation_data.music_settings || backgroundMusicData.find(music => music.name === "Trending"));
    const [selectedTextStyle, setSelectedTextStyle] = useState({...(short.generation_data?.text_settings || lastGenerationData?.text_settings || textStylesData[0])});

    const [title, setTitle] = useState(short.title || '');
    const [language, setLanguage] = useState(languages.find((language) => language.code === (short.language || 'en')));
    const [scriptPrompt, setScriptPrompt] = useState<string | null>(null);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [generatingScript, setGeneratingScript] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [socialMediaCaption, setSocialMediaCaption] = useState(short.description || '');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [textGenerationPrompt, setTextGenerationPrompt] = useState('');
    const [voicePlaybackRate, setVoicePlaybackRate] = useState<number>(short.generation_data.voice_settings?.voice_playback_rate || 1.2);
    const [enableImageAnimations, setEnableImageAnimations] = useState( short.generation_data?.image_settings?.enable_image_animations || true);
    const [showDisabledForFreeUsersModal, setShowDisabledForFreeUsersModal] = useState(false);
    const scriptLength = script?.length || 0;
    const SCRIPT_MAX_LENGTH =  isPaidUser ? 2000 : 1000;
    const SCRIPT_MIN_LENGTH = 50;
    const VOICE_PLAYBACK_RATE_MAX = 1.5;

    const handleScriptChange = (e) => {
        setScript(e.target.value);
    };

    useEffect(() => {
    }, [voicePlaybackRate]);

    useEffect(() => {
        fetchVoices();
    }, [language]);

    const fetchVoices = async () => {
        try {
            const response = await fetch('/voices.json?language=' + language.code, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                }
            });
            const voices = await response.json();
            if (voices.length === 0) {
                return;
            }
            if (voices.find(voice => voice.id === selectedAudioStyle.id) === undefined) {
                // If selected voice is not available for selected language, set first voice as selected
                setSelectedAudioStyle(voices[0]);
            }
            setAudioStyles(voices)
        } catch (error) {
            console.error('Error fetching voices:', error);
        }
    }

    const estimateCredits = getEstimatedCreditsConsumed(script, 8, selectedAudioStyle.service);

    function getEstimatedCreditsConsumed(text: string, imageEveryNthSecond: number = 5, ttsService: string): number {
        let costPerCharacter: number = 0.00003;
        if (ttsService === 'elevenlabs') {
            costPerCharacter = 0.00022;
        }
        const costPerImage: number = 0.005;
        const duration: number = text.length * 0.06; // assuming 500 chars spoken in 30 seconds

        // Calculate cost for text
        const textCost: number = text.length * costPerCharacter;

        // Calculate cost for images
        const imageCost: number = Math.ceil(duration / imageEveryNthSecond) * costPerImage;

        // Calculate total cost
        const totalCost: number = textCost + imageCost;
        const creditsUsed: number = Math.ceil(totalCost * 100);

        return creditsUsed;
    }

    const showToastView = (message: string) => {
        showJetToast(message);
        setToastMessage(message);
        setShowToast(true);
        setTimeout(function () {
            setShowToast(false);
        }, 3000);
    }

    const showDelayedToast = (message: string) => {
        setTimeout(function () {
            showToastView(message);
        }, 1000);
    }

    const validateForm = () => {
        const scriptValid = script && script.trim().length > 0;
        if (!scriptValid) {
            showToastView("Please enter a script");
            return false;
        }
        if (script.length < SCRIPT_MIN_LENGTH) {
            showToastView(`Script must be at least ${SCRIPT_MIN_LENGTH} characters`);
            return false;
        }
        if (scriptLength > SCRIPT_MAX_LENGTH) {
            showToastView(`Script exceeds maximum length of ${SCRIPT_MAX_LENGTH} characters`);
            return false;
        }
        return true;
    }

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        if (!validateForm()) {
            return;
        }
        if (submitLoading) {
            return;
        }
        try {
            let url = short.id ? `/shorts/${short.id}.json` : '/shorts.json';
            let method = short.id ? 'PATCH' : 'POST';
            setSubmitLoading(true);
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    short: {
                        title,
                        language: language.code,
                        script: script,
                        description: socialMediaCaption,
                        generation_data: {
                            image_settings: {...selectedImageStyle, enable_image_animations: enableImageAnimations},
                            voice_settings: {...selectedAudioStyle, voice_playback_rate: voicePlaybackRate},
                            music_settings: selectedBackgroundMusic,
                            text_settings: selectedTextStyle,
                            generation_prompts: {
                                text_generation_prompt: textGenerationPrompt
                            }
                        }
                    }
                }),
            });
            const shortsResponse = await response.json();
            console.log(shortsResponse);
            if (!response.ok) {
                if (shortsResponse.error && shortsResponse.code == "DISABLED_FREE_USERS") {
                    setSubmitLoading(false);
                    setShowDisabledForFreeUsersModal(true);
                    setShowConfirmationPopup(false);
                } else {
                    // @ts-ignore
                    for (let [key, errors] of Object.entries(shortsResponse)) {
                        if (Array.isArray(errors)) {
                            errors.forEach((error) => showToastView(`${key} ${error}`));
                        } else if (typeof errors === 'string') {
                            console.log("YOLO here");
                            showToastView(errors);
                        }
                    }
                    setSubmitLoading(false);
                }
                return;
            }
            // const shortUrl = `/shorts/${shortsResponse.id}`;
            // const frame = document.getElementById("modal");
            // // @ts-ignore
            // frame.src = shortUrl;
            // // @ts-ignore
            // frame.reload();
            showToastView("Video submitted for creation successfully");
            Turbo.visit("/");
            setSubmitLoading(false);
        } catch (error: any) {
            console.error('Error submitting form:', error);
            console.log("YOLO here2");
            showToastView("Error creating video");
            setSubmitLoading(false);
        }
    };

    const handleImageStyleSelected = (style) => {
        setSelectedImageStyle(style);
    }

    const handleAudioStyleSelected = (style) => {
        setSelectedAudioStyle(style);
    }

    const handleBackMusicSelected = (style) => {
        setSelectedBackgroundMusic(style);
    }

    const handleScriptPromptChange = (e) => {
        setScriptPrompt(e.target.value);
    }

    const onGenerateScriptClick = async () => {
        const isPromptSelected = selectedPrompt != null && promptTopic && promptTopic.trim().length > 0;
        const isScriptPromptValid = scriptPrompt && scriptPrompt.trim().length > 0;
        if (!isPromptSelected && !isScriptPromptValid) {
            if (selectedPrompt != null && !promptTopic) {
                console.log(selectedPrompt, "Selected Prompt");
                showToastView("Please enter a topic for the selected prompt");
                return;
            }
            showToastView("Please select or enter a script prompt");
            return;
        }
        if (isScriptPromptValid && isPromptSelected) {
            showToastView("Either enter prompt or select predefined prompts, not both.");
            return;
        }
        let prompt = '';
        if (isScriptPromptValid) {
            prompt = scriptPrompt;
        } else {
            prompt = selectedPrompt.prompt.replace("<TOPIC>", promptTopic);
        }
        setTextGenerationPrompt(prompt);
        prompt = prompt.trim() + `Please generate the output in ${language.name}`;
        try {
            let url = '/shorts/generate_script.json';
            setGeneratingScript(true);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    prompt: prompt,
                    language: language.name
                }),
            });
            const scriptResponse = await response.json();
            console.log(scriptResponse);
            if (!response.ok) {
                // @ts-ignore
                showToastView(scriptResponse || "Error generating script");
                setGeneratingScript(false);
                return;
            }
            setScript(scriptResponse.script);
            showToastView("Script generated successfully");
            setGeneratingScript(false);
        } catch (error) {
            setGeneratingScript(false);
            console.error('Error generating script:', error);
            showToastView("Error generating script");
        }
    }

    const onGenerateClick = () => {
        if (!validateForm()) {
            return;
        }
        setShowConfirmationPopup(true);
    }

    return (
        <div className={`px-2 lg:px-4 w-full lg:mx-auto lg:w-3/4 mt-4`}>
            <h1 className="font-bold text-4xl">New Video</h1>
            <div className="contents">
                {short.errors && short.errors.length > 0 ? (
                    <div className="bg-red-50 text-red-500 px-3 py-2 font-medium rounded-lg mt-3">
                        <h2>{short.errors.length} {short.errors.length === 1 ? 'error' : 'errors'} prohibited this short
                            from being saved:</h2>
                        <ul>
                            {short.errors.map((error, index) => (
                                <li key={index}>{error.full_message}</li>
                            ))}
                        </ul>
                    </div>
                ): null}

                <div className="mt-5">
                    <div className="my-4">
                        <Popover>
                            <PopoverTrigger>
                                    <span className={"flex flex-row gap-2 items-center"}>
                                        Select Language
                                    <Info size={14}/>
                                    </span>
                            </PopoverTrigger>
                            <PopoverContent side={"top"} className={"max-w-5xl"}>The language your video script will be
                                in.</PopoverContent>
                        </Popover>
                    </div>
                    <LanguageSelection
                        languages={languages}
                        selectedLanguage={language}
                        onLanguageSelected={(language) => setLanguage(language)}
                        />
                    {language.code !== "en" && !isPaidUser ? <div className="mx-4 text-sm mt-2 text-gray-500">
                        We suggest upgrading to our paid plans to get better results with languages other than English.
                    </div>: null}
                </div>

                <Accordion type="single" collapsible defaultValue="item1">
                    <AccordionItem value="item1" className="border-none">
                        <AccordionTrigger>
                            <div
                                className={`rounded-lg font-bold py-3 text-center cursor-pointer block bg-white text-textCaption`}>
                                Generate Script
                            </div>
                        </AccordionTrigger>
                        <AccordionContent className="bg-surface p-4 rounded shadow-md">
                            <Tabs defaultValue="generate">
                                {/*<TabsList className="bg-gray-200">*/}
                                {/*    <TabsTrigger value="generate">Select Prompt</TabsTrigger>*/}
                                {/*    /!*<TabsTrigger value="script">Type Prompt</TabsTrigger>*!/*/}
                                {/*</TabsList>*/}
                                <TabsContent value="script">
                                    <div className="my-2">
                                        <TextareaAutosize
                                            id="script_prompt"
                                            name="script_prompt"
                                            minRows={6}
                                            placeholder={"Generate a short story about ..."}
                                            onChange={handleScriptPromptChange}
                                            value={scriptPrompt}
                                            className={`block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full`}
                                        />
                                    </div>
                                </TabsContent>
                                <TabsContent value="generate">
                                    <div className="my-2" onClick={(event) => {
                                        event.preventDefault();
                                        return false;
                                    }}>
                                        Choose to generate script from our prompts.
                                        <Select
                                            value={selectedPrompt?.prompt || "Select Prompt"}
                                            onValueChange={(value) => setSelectedPrompt(myScriptPrompts.find(prompt => prompt.prompt === value))}>
                                            <SelectTrigger className="w-full">
                                                <SelectValue placeholder="Select Prompt"/>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectLabel>Prompts</SelectLabel>
                                                    {myScriptPrompts.map((prompt, index) => (
                                                        <SelectItem key={index}
                                                                    value={prompt.prompt}><span>{prompt.title}</span></SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className="mt-3 mb-2">Topic for {selectedPrompt?.title} :</div>
                                        {selectedPrompt?.example ?
                                            <div className="font-medium">Example: {selectedPrompt.example}</div>: null}
                                        <TextareaAutosize
                                            id="script"
                                            name="script"
                                            placeholder={"Enter your topic"}
                                            minRows={1}
                                            onChange={(e) => setPromptTopic(e.target.value)}
                                            value={promptTopic}
                                            className={`block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full`}
                                        />
                                    </div>
                                </TabsContent>
                            </Tabs>
                            <PrimaryButton text={"Generate Script"} onClick={onGenerateScriptClick}
                                           isLoading={generatingScript}/>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>

                <div className="mt-5 mb-3">
                    <label htmlFor="script">
                        <Popover>
                            <PopoverTrigger>
                                    <span className={"flex flex-row gap-2 items-center"}>
                                        Script
                                    <Info size={14}/>
                                    </span>
                            </PopoverTrigger>
                            <PopoverContent side={"top"} className={"text-sm max-w-5xl"}>The content in Script below
                                will be the
                                final video script that will be spoken by AI. Write your own or generate
                                above.</PopoverContent>
                        </Popover>
                    </label>
                    <TextareaAutosize
                        id="script"
                        name="script"
                        minRows={6}
                        disabled={disableScript}
                        onChange={handleScriptChange}
                        value={script}
                        className={`${disableScript ? "bg-gray-200" : ""} block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full`}
                    />
                </div>
                <div className="flex justify-between text-sm">
                    <div className="flex justify-start">Characters:&nbsp; <span
                        className={`${scriptLength > SCRIPT_MAX_LENGTH || scriptLength < SCRIPT_MIN_LENGTH ? "text-red-500" : "text-black"}`}>{scriptLength}</span>/{SCRIPT_MAX_LENGTH}
                    </div>
                    {estimateCredits > 0 ?
                        <div className="flex justify-end">Estimated credits: {estimateCredits}</div>: null}
                </div>
                <div className="text-sm">Approximate duration of video: {(scriptLength * 0.06).toFixed(2)} seconds</div>
                <div className="my-5">
                    <label htmlFor="script">Social Media Caption</label>
                    <TextareaAutosize
                        id="caption"
                        name="caption"
                        minRows={1}
                        placeholder={"(Optional) Caption for social media post. Leave blank to generate automatically."}
                        onChange={(e) => setSocialMediaCaption(e.target.value)}
                        value={socialMediaCaption}
                        className={`block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full`}
                    />
                </div>

                <div className="text-lg my-4">
                    Choose a visual style for your video
                </div>
                <ImageStyles
                    selectedImageStyle={selectedImageStyle}
                    imageStyles={imageStylesData.filter(style => style?.hidden !== true)}
                    onImageStyleSelected={handleImageStyleSelected}/>

                <div className="flex flex-col  lg:flex-row lg:justify-around">
                    <div>
                        <div className="text-lg my-4">
                            Choose a voice over
                        </div>
                        <AudioSelection
                            isPaidUser={isPaidUser}
                            selectedAudioStyle={selectedAudioStyle}
                            audioStyles={audioStyles.filter(style => style?.enabled != false)}
                            playbackRate={voicePlaybackRate}
                            placeholder={"Select voice"}
                            searchPlaceholder={"Search voice..."}
                            onAudioStyleSelected={handleAudioStyleSelected}/>
                    </div>
                    <div>
                        <div className="text-lg my-4">
                            Select Background music
                        </div>
                        <AudioSelection
                            isPaidUser={isPaidUser}
                            selectedAudioStyle={selectedBackgroundMusic}
                            audioStyles={backgroundMusicData.sort((a, b) => a.position - b.position)}
                            placeholder={"Select music"}
                            searchPlaceholder={"Search music..."}
                            onAudioStyleSelected={handleBackMusicSelected}/>
                    </div>
                </div>
                <div className="my-4">
                    <div className="text-lg my-2">
                        <Popover>
                            <PopoverTrigger>
                                    <span className={"flex flex-row gap-2 items-center"}>
                                       Playback speed for voice <span
                                        className="text-sm">(1x-{VOICE_PLAYBACK_RATE_MAX}x)</span>
                                    <Info size={14}/>
                                    </span>
                            </PopoverTrigger>
                            <PopoverContent side={"top"} className={"text-sm max-w-5xl"}>You can play the voiceover
                                above after changing playback rate to get idea of speed of audio.</PopoverContent>
                        </Popover>
                    </div>
                    <div className="mx-6 mt-4">
                        <Slider
                            min={1}
                            value={[voicePlaybackRate]}
                            max={VOICE_PLAYBACK_RATE_MAX}
                            className={"showStepBar"}
                            step={0.1}
                            onValueChange={(value) => setVoicePlaybackRate(value[0])}/>
                    </div>
                </div>

                <div className="text-lg my-4">
                    Caption Style
                </div>
                <TextStyles
                    textStyles={textStylesData}
                    selectedTextStyle={selectedTextStyle}
                    onTextStyleSelected={setSelectedTextStyle}
                />
                <div className="flex cursor-pointer flex-col px-4 py-2 border border-gray-200 rounded" onClick={() => setEnableImageAnimations(!enableImageAnimations)}>
                    <div className="flex cursor-pointer flex-row items-center">
                        <Checkbox checked={enableImageAnimations} />
                        <label htmlFor="enable_image_animations"
                               className="w-full cursor-pointer ms-2 text-sm font-medium text-gray-900">Enable Image Animations</label>
                    </div>
                    <p className="text-textCaption text-sm">Show the scaling and sideways image animation</p>
                </div>

                {short.video && short.video.attached ? (
                    <div className="flex justify-center">
                        <video className="mt-2 w-full lg:w-[300px] max-w-[300px] aspect-[9/16] rounded-lg" controls>
                            <source src={short.video.url} type="video/mp4"/>
                        </video>
                    </div>
                ): null}

                <div className="inline-block mt-4">
                    <PrimaryButton text={"Generate Video"} onClick={onGenerateClick}/>
                </div>
            </div>
            {showToast ? <dialog className="fixed top-10 rounded-lg px-5 py-2 bg-surface z-[80]" open={true}>
                <div className="text-md">{toastMessage}</div>
            </dialog>: null}
            {showConfirmationPopup ?
                <dialog
                    className="fixed top-1/2 rounded-lg shadow-md px-5 py-2 bg-surface z-[80] opacity-100 backdrop:bg-gray-400 backdrop:bg-opacity-90"
                    open={true}>
                    <div className="text-lg">
                    <div className="my-4">The video will consume {estimateCredits} credits. Are you sure you want to
                            generate video?
                        </div>
                        <div className="flex justify-center gap-4">
                            <GhostButton onClick={() => setShowConfirmationPopup(false)} text="Cancel"></GhostButton>
                            <PrimaryButton text={"Yes, Generate"} onClick={handleSubmit} isLoading={submitLoading}/>
                        </div>
                    </div>
                </dialog>: null}
            <DisabledForFreeUsersModal open={showDisabledForFreeUsersModal} setOpen={setShowDisabledForFreeUsersModal}/>
        </div>
    )
}

CreateShort.propTypes = {
    short: PropTypes.object.isRequired,
    actionName: PropTypes.string.isRequired,
    scriptPrompts: PropTypes.array.isRequired,
    lastGenerationData: PropTypes.object,
    isPaidUser: PropTypes.bool
};

export default CreateShort
