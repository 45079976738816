import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {ScrollArea, ScrollBar} from "../ui/scroll-area";

const ImageStyles = ({imageStyles, onImageStyleSelected, selectedImageStyle}) => {
    return (
        <ScrollArea className="w-full w-[100%] whitespace-nowrap rounded-md border">
            <div className="flex w-max space-x-4 p-4">
                {imageStyles.map((style) => (
                    <figure key={style.name} className={`shrink-0 cursor-pointer ${selectedImageStyle.id == style.id ? "border rounded p-1" : ""}`} onClick={() => onImageStyleSelected(style)}>
                        <div className="overflow-hidden rounded-md relative">
                            <img
                                src={style.image}
                                alt={`Image style ${style.name}`}
                                className="aspect-[9/16] w-[150px] object-cover"
                            />
                            {style.recommended && <div
                                className="absolute opacity-80 bottom-0 mb-2 left-1/2 transform -translate-x-1/2 bg-yellow-300 text-xs px-4 py-1 rounded-lg">
                                Recommended
                            </div>}
                        </div>
                        <figcaption className="pt-2 text-xs text-muted-foreground">
                            <span className="font-semibold text-foreground">
                {style.name}
              </span>
                        </figcaption>
                    </figure>
                ))}
            </div>
            <ScrollBar orientation="horizontal"/>
        </ScrollArea>
    )
}

ImageStyles.propTypes = {
    imageStyles: PropTypes.array.isRequired,
    onImageStyeSelected: PropTypes.func
};

export default ImageStyles
