import React from "react"
import PropTypes from "prop-types"

export interface Persona {
    title: string
    description: string
}

const PersonaItem = (props) => {
    return (
        <div onClick={props.onClick}
             className={`mt-4 shadow flex flex-col items-center rounded-lg py-4 px-4 cursor-pointer ${props.selected ? "bg-primary text-white" : "bg-white text-black"}`}>
            <h2 className="font-medium text-2xl">{props.persona.title}</h2>
            <div className={`mt-4 ${props.selected ? "text-white" : "text-textCaption"}`}>{props.persona.description}</div>
        </div>
    )
}

PersonaItem.propTypes = {
    persona: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func
}

export default PersonaItem
