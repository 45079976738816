import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {DummyDialog} from "./DummyDialog";

const ShortItem = ({short, edit_short_path, publish_short_path, thumbnail_url, short_path, card_background, border}) => {

    return (
        <div
            className={`col-span-1 rounded-lg bg-${card_background} ${border} shadow p-4 relative items-center justify-center`}>
            {short.post_status == "draft" ? (
                <div className="flex justify-end gap-3">
                    <a href={edit_short_path} data-turbo-frame="modal">
                        <svg  viewBox="0 0 24 24" fill="currentColor"
                             aria-hidden="true" className="h-6 p-1 w-auto text-slate-500">
                            <path
                                d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z"></path>
                        </svg>

                    </a>
                    <a data-turbo-confirm="This will publish video and cannot be edited later. Publish?"
                       data-turbo-method="post" href={publish_short_path}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                             aria-hidden="true" className="h-6 w-auto text-slate-500">
                            <path fillRule="evenodd"
                                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                  clipRule="evenodd"></path>
                        </svg>

                    </a></div>
            ) : (<div className="flex justify-end gap-3">
                <svg  viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
                     className="h-6 w-auto text-accent">
                    <path fillRule="evenodd"
                          d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"></path>
                </svg>
            </div>)}

            <div className="mx-4 mb-2 items-center justify-center flex flex-col">
                <div className="min-h-[30px] font-medium line-clamp-1 text-center text-lg text-ellipsis overflow-hidden">
                    {short.title || ''}
                </div>
            </div>
            <a href={short_path} data-turbo-frame="modal">
                {(short.status == "success" || thumbnail_url) ? (
                    <div className="flex justify-center relative z-10 top-0 left-0 w-full">
                        <img className="mt-2 w-full h-[300px] object-cover rounded-lg" src={thumbnail_url}/>
                    </div>
                ) : (<>
                        <div className="flex justify-center relative z-10 top-0 left-0 w-full">
                            <svg className="animate-spin text-primary inline w-14 h-14 lg:h-full lg:w-full m-14"
                                 viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"/>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"/>
                            </svg>
                        </div>
                        <div className="flex bg-primaryLight justify-center font-bold text-primary rounded py-2">Check
                            Status
                        </div>
                    </>
                )}

                <div
                    className="absolute text-sm inline-block z-50 bottom-10 items-center left-1/2 -translate-x-1/2 bg-opacity-80 px-2 py-1 bg-primaryLight rounded-2xl">
                    <svg
                         aria-hidden="true"
                         role="img" className="w-4 h-4 inline-block" width="1em" height="1em" viewBox="0 0 24 24">
                        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                           strokeWidth="2">
                            <path d="M6 5h12l3 5l-8.5 9.5a.7.7 0 0 1-1 0L3 10z"></path>
                            <path d="M10 12L8 9.8l.6-1"></path>
                        </g>
                    </svg>
                    {short.credits_consumed}
                </div>
            </a>
        </div>

    )
}

ShortItem.propTypes = {
    short: PropTypes.object,
    edit_short_path: PropTypes.string,
    publish_short_path: PropTypes.string,
    template: PropTypes.object,
    thumbnail_url: PropTypes.string,
    short_path: PropTypes.string
};

export default ShortItem
