import React, { useState } from 'react';
import { CircleX } from 'lucide-react';

function PersonaForm({ persona }) {
    const [errors, setErrors] = useState([]);
    const [prompts, setPrompts] = useState(persona.script_prompts || []);
    const [formData, setFormData] = useState({
        title: persona.title || '',
        description: persona.description || '',
        persona_identifier: persona.persona_identifier || '',
        brand_title_placeholder: persona.brand_title_placeholder || '',
        brand_description_placeholder: persona.brand_description_placeholder || ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let url = persona.id ? `/personas/${persona.id}.json` : '/personas.json';
            let method = persona.id ? 'PATCH' : 'POST';
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({ persona: { ...formData, script_prompts: prompts } })
            });
            if (!response.ok) {
                throw new Error('Failed to submit form');
            }
            // Redirect to persona page
            window.location.href = '/personas';
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error state or display error message
        }
    };

    const handleAddPrompt = () => {
        setPrompts([...prompts, {}]);
    };

    const handlePromptChange = (index: number, value: string, key: string) => {
        const updatedPrompts = [...prompts];
        updatedPrompts[index][key] = value;
        setPrompts(updatedPrompts);
    };

    const handleRemovePrompt = (index) => {
        const updatedPrompts = [...prompts];
        updatedPrompts.splice(index, 1);
        setPrompts(updatedPrompts);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <div>
            {errors.length > 0 ? (
                <div className="bg-red-50 text-red-500 px-3 py-2 font-medium rounded-lg mt-3">
                    <h2>{errors.length} {errors.length === 1 ? 'error' : 'errors'} prohibited this template from being saved:</h2>
                    <ul>
                        {errors.map((error, index) => (
                            <li key={index}>{error.full_message}</li>
                        ))}
                    </ul>
                </div>
            ): null}

            <form onSubmit={handleSubmit} className="contents">
                <div className="my-5">
                    <label htmlFor="title">Title</label>
                    <input type="text" id="title" name="title"
                           className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                           value={formData.title} onChange={handleChange}/>
                </div>

                <div className="my-5">
                    <label htmlFor="description">Description</label>
                    <input type="text" id="description" name="description"
                           className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                           value={formData.description} onChange={handleChange}/>
                </div>

                <div className="my-5">
                    <label htmlFor="persona_identifier">Persona Identifier</label>
                    <input type="text" id="persona_identifier" name="persona_identifier"
                           className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                           value={formData.persona_identifier} onChange={handleChange}/>
                </div>

                <div className="my-5">
                    <label htmlFor="brand_title_placeholder">Brand Title Placeholder</label>
                    <input type="text" id="brand_title_placeholder" name="brand_title_placeholder"
                           className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                           value={formData.brand_title_placeholder} onChange={handleChange}/>
                </div>

                <div className="my-5">
                    <label htmlFor="brand_description_placeholder">Brand Description Placeholder</label>
                    <input type="text" id="brand_description_placeholder" name="brand_description_placeholder"
                           className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                           value={formData.brand_description_placeholder} onChange={handleChange}/>
                </div>

                <div className="field flex flex-col gap-2" id="prompt-fields">
                    {prompts.map((prompt, index) => (
                        <div key={index} className="flex items-center justify-center gap-1 bg-gray-200 rounded p-4">
                            <div className="flex flex-col w-full">
                                <div className="my-5">
                                    <label htmlFor="prompt_title">Prompt Title</label>
                                    <input type="text" id="prompt_title"
                                           name="prompt_title"
                                           className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                                           value={prompt?.title}
                                           onChange={(e) => handlePromptChange(index, e.target.value, 'title')}/>
                                </div>
                                <div className="my-5">
                                    <div>Prompt text</div>
                                    <textarea rows={3}
                                              placeholder="Generate a story for"
                                              className="prompt-field block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                                              value={prompt?.prompt}
                                              onChange={(e) => handlePromptChange(index, e.target.value, 'prompt')}
                                    />
                                </div>
                                <div className="my-5">
                                    <label htmlFor="prompt_title">Prompt Example</label>
                                    <input type="text" id="prompt_example"
                                           name="prompt_example"
                                           className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                                           value={prompt?.example}
                                           onChange={(e) => handlePromptChange(index, e.target.value, 'example')}/>
                                </div>
                            </div>

                            <button type="button" onClick={() => handleRemovePrompt(index)} className="text-gray-500"
                                    aria-label="Remove Prompt">
                                <CircleX size="20"/>
                            </button>
                        </div>
                    ))}
                </div>

                <div className="my-5">
                    <button type="button" onClick={handleAddPrompt}
                            className="add-prompt bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-blue-700">Add
                        Prompt
                    </button>
                </div>

                <div className="inline">
                    <button type="submit"
                            className="rounded-lg py-3 px-5 bg-blue-600 text-white inline-block font-medium cursor-pointer">Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export default PersonaForm;
