"use client"

import * as React from "react"
import {useEffect, useRef} from "react"
import {Check, ChevronsUpDown, Pause, Play, Sparkles} from "lucide-react"

import {cn} from "../../lib/utils"
import {Button} from "../ui/button"
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/command";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../ui/tooltip";
import {showJetToast} from "../Toast";


export function AudioSelection({
                                   audioStyles,
                                   onAudioStyleSelected,
                                   selectedAudioStyle,
                                   searchPlaceholder,
                                   placeholder,
                                   isPaidUser,
                                   playbackRate = 1
                               }) {
    const [open, setOpen] = React.useState(false)
    const audioRef = useRef(null);
    const [currentAudio, setCurrentAudio] = React.useState(null);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = playbackRate;
        }
    }, [playbackRate]);

    const onOpenChange = (nextOpen) => {
        setOpen(nextOpen);
        if (!nextOpen) {
            pauseAudio();
        }
    }

    const pauseAudio = () => {
        try {
            if (audioRef.current) {
                audioRef.current.pause();
                setCurrentAudio(null);
            }
        } catch (error: any) {

        }
    }

    const handlePlayButtonClick = (item) => {
        try {
            if (item.audio == currentAudio?.audio) {
                if (audioRef.current) {
                    audioRef.current.pause();
                }
                setCurrentAudio(null);
                return;
            }
            if (audioRef.current) {
                audioRef.current.pause();
            }
            const audio = new Audio(item.audio);
            audio.playbackRate = playbackRate;
            audio.play();
            setCurrentAudio(item);
            audioRef.current = audio;
        } catch (error: any) {
        }
    };

    return (
        <Popover open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[300px] justify-between notranslate" translate="no"
                >
                    {selectedAudioStyle ? selectedAudioStyle.name : (placeholder || "Select audio")}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[300px] p-0 h-[300px]" onOpenAutoFocus={(e) => e.preventDefault()}>
                <Command className="w-full">
                    <CommandInput
                        placeholder={searchPlaceholder || "Search audio..."}
                        className="focus:ring-0 focus:border-transparent border-none"/>
                    <CommandEmpty>No audio found.</CommandEmpty>
                    <CommandList className="notranslate" translate="no">
                        <CommandGroup>
                            {audioStyles.map((audio) => (
                                <CommandItem
                                    key={audio.name}
                                    value={audio.name}
                                    className={"cursor-pointer"}
                                    onSelect={(currentValue) => {
                                        const selectedAudio = audioStyles.find((audio) => audio.name === currentValue);
                                        if (selectedAudio.paid && !isPaidUser) {
                                            showJetToast("Upgrade plan to use this audio")
                                            return;
                                        }
                                        console.log("currentValue", currentValue)
                                        onAudioStyleSelected(audioStyles.find((audio) => audio.name === currentValue));
                                        setOpen(false);
                                        pauseAudio();
                                    }}
                                >
                                    <TooltipProvider delayDuration={isPaidUser || !audio.paid ? 9999999 : 200}>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <div
                                                    className="flex flex-row justify-between items-center w-full min-w-full">
                                                    <div className="flex flex-row cursor-pointer items-center">
                                                        <Check
                                                            className={cn(
                                                                "mr-2 h-4 w-4",
                                                                selectedAudioStyle?.id === audio.id ? "opacity-100" : "opacity-0"
                                                            )}
                                                        />
                                                        <div className="">{audio.name}</div>
                                                    </div>
                                                    <div className="flex flex-row gap-2">
                                                        {audio.paid && <Sparkles color="#3b0053"/>}
                                                        {audio.audio ? <div
                                                            className="cursor-pointer px-4"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handlePlayButtonClick(audio);
                                                            }}>
                                                            {currentAudio?.audio == audio.audio ? <Pause/> : <Play/>}
                                                        </div> : null}
                                                    </div>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <div className="text-sm text-popover-foreground">
                                                    Upgrade plan to use this audio
                                                </div>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
