import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import YoutubeAccount from "./YoutubeAccount";
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../ui/select";
import {showJetToast} from "../Toast";
import PrimaryButton from "../common/PrimaryButton";

const YoutubeCallback = ({brand, accounts, categories}) => {

    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [category, setCategory] = useState(null);
    const [selectedAccountId, setSelectedAccount] = useState(accounts[0]?.id);
    console.log(categories);

    const handleAccountClick = () => {
        if (!category) {
            showJetToast('Please select a category for the videos');
            return;
        }
        if (loading) return;
        setLoading(true);
        setLoadingText('Setting up your account...');
        fetch(`/pages/${brand.id}/youtube/select_account`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // @ts-ignore
                "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
            },
            method: 'POST',
            body: JSON.stringify({channel_id: selectedAccountId, category: category})
        }).then(response => response.json()).then(data => {
            if (data.error) {
            } else {
                setLoadingText('Youtube account connected successfully! Redirecting...');
                window.location.href = '/';
            }
        }).catch((error) => {
            setLoadingText('Youtube account connection failed. Redirecting...');
            // window.location.href = '/';
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="mt-12 flex justify-center items-center flex-col">
            <h1 className="font-bold text-2xl">Select Youtube Channel</h1>
            {!accounts || accounts.length === 0 ? <div className="mt-4">No Youtube channels found. Create one and retry connecting.</div>: null}
            {loading ? <div id="fb-spinner" className="hidden m-2">
                <svg className="animate-spin inline h-5 w-5 m-3" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>: null}
            {loadingText ? <div id="loading-text" className="m-4">{loadingText}</div>: null}
            {accounts.length > 0 ?
                <ul className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    {accounts.map(account => (
                        <li key={account.id}>
                            <YoutubeAccount account={account}
                                            selected={selectedAccountId === account.id}
                                            handleAccountClick={() => setSelectedAccount(account.id)}/>
                        </li>
                    ))}
                </ul>: null}
            <div className="text-lg my-4 font-medium">
                Select Video Category
            </div>
            <Select
                value={`${category ? category["id"]: null}`}
                onValueChange={(value) => setCategory(categories.find(category => category["id"] === value))}>
                <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select Video Category"/>
                </SelectTrigger>
                <SelectContent className="mt-8">
                    <SelectGroup>
                        <SelectLabel>Category for Video</SelectLabel>
                        {categories.map((category, index) => (
                            <SelectItem key={index} value={category["id"]}><span>{category["snippet"]["title"]}</span></SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </Select>
            <div className="mt-8">
                <PrimaryButton text={"Confirm"} onClick={handleAccountClick} isLoading={loading}/>
            </div>
        </div>
    )
}

YoutubeCallback.propTypes = {
    brand: PropTypes.object,
    accounts: PropTypes.array
};

export default YoutubeCallback
