import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import InstagramAccount from "./InstagramAccount";

const InstagramCallback = (props) => {

    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [accounts, setAccounts] = useState([]);

    const handleAccountClick = (accountId) => {
        setLoading(true);
        setLoadingText('Setting up your account...');
        fetch('/instagram/select_account', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // @ts-ignore
                "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
            },
            method: 'POST',
            body: JSON.stringify({instagram_user_id: accountId})
        }).then(response => response.json()).then(data => {
            if (data.error) {
            } else {
                setLoadingText('Instagram account connected successfully! Redirecting...');
                window.location.href = '/';
            }
        }).catch((error) => {
            setLoadingText('Instagram account connection failed. Redirecting...');
            window.location.href = '/';
        });
    }

    useEffect(() => {
        console.log('InstagramCallback');
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const error = urlParams.get('error');
        console.log('code', code);
        setLoading(true);
        if (code) {
            setLoading(true);
            fetch(`/pages/${props.brand.id}/instagram/page_selection`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    code: code
                })
            }).then(response => response.json())
                .then(data => {
                    setLoadingText('')
                    setAccounts(data)
                }).finally(() => {
                setLoading(false);
            }).catch((error) => {
                setLoadingText('Instagram account connection failed. Redirecting...');
                window.location.href = '/';
                console.log('Instagram account connection failed. Redirecting..1.');
            });
        } else if (error) {
            setLoadingText('Instagram account connection failed. Redirecting...');
            console.log('Instagram account connection failed. Redirecting...');
            window.location.href = '/';
        }
    }, []);

    return (
        <div className="mt-8 flex justify-center items-center flex-col">
            <h1 className="font-bold text-2xl">Select Instagram Account</h1>
            {loading ? <div id="fb-spinner" className="hidden m-2">
                <svg className="animate-spin inline h-5 w-5 m-3" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>: null}
            {loadingText ? <div id="loading-text" className="m-4">{loadingText}</div>: null}
            {accounts.length > 0 ? <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {accounts.map(account => (
                    <li key={account.id}>
                        <InstagramAccount account={account} handleAccountClick={handleAccountClick}/>
                    </li>
                ))}
            </ul>: null}
        </div>
    )
}

InstagramCallback.propTypes = {
    brand: PropTypes.object
};

export default InstagramCallback
