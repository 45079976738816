import React, {useEffect, useState} from "react"
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../ui/tabs";
import {ScrollArea, ScrollBar} from "../ui/scroll-area";
import {Slider} from "../ui/slider";
import {Checkbox} from "../ui/checkbox";
import PropTypes from "prop-types";

const TextStyles = ({
                        textStyles,
                        selectedTextStyle,
                        onTextStyleSelected,
                    }) => {
    const LINES_ARRAY = [`${selectedTextStyle.name} will`, `look like`, `this!`];
    const [lines, setLines] = useState([...LINES_ARRAY]);
    const [maximumLines, setMaximumLines] = useState<number>(parseInt(selectedTextStyle?.text_maximum_lines || "2"));
    const [maximumWordsPerLine, setMaximumWordsPerLine] = useState<number>(parseInt(selectedTextStyle?.text_maximum_words_per_line || "2"));
    const [top, setTop] = useState<string>(selectedTextStyle?.text_margin_top_percent || "40%");
    const customStyles = {
        '--js-font-size': '90px',
        '--js-outline-size': selectedTextStyle.text_stroke_width,
        '--js-text-shadow-offset': selectedTextStyle.text_shadow_offset,
        '--js-text-shadow-offset-large': selectedTextStyle.text_shadow_blur,
        '--js-background-top': '10px',
        '--js-background-left': '-20px',
        '--js-background-bottom': '50px',
        '--js-background-width-offset': '40px',
        '--js-background-height-offset': '20px',
        '--js-line-height': `1.8`,
        '--text-color': selectedTextStyle.text_color,
        '--outline-color': selectedTextStyle.text_stroke_color,
        '--spoken-text-color': selectedTextStyle.highlighted_text_color,
        '--spoken-outline-color': selectedTextStyle.highlighted_text_stroke_color,
        '--background-color': selectedTextStyle.popping_color_behind_text,
        '--font-family': selectedTextStyle.font_family,
        '--font-weight': '900'
    };

    let neonSpokenGlowColor = selectedTextStyle.highlighted_text_stroke_color;
    let neonGlowColor = selectedTextStyle.text_stroke_color;

    useEffect(() => {
        neonSpokenGlowColor = selectedTextStyle.highlighted_text_stroke_color;
        neonGlowColor = selectedTextStyle.text_stroke_color;
    }, [selectedTextStyle]);


    const onTextStyleUpdated = (key, value) => {
        onTextStyleSelected({
            ...selectedTextStyle,
            [key]: value
        })
    }

    useEffect(() => {
        if (selectedTextStyle) {
            setMaximumWordsPerLine(parseInt(selectedTextStyle?.text_maximum_words_per_line || "2"));
            setMaximumLines(parseInt(selectedTextStyle?.text_maximum_lines || "2"));
            setTop(selectedTextStyle?.text_margin_top_percent || "50%")
        }
    }, [selectedTextStyle]);

    useEffect(() => {
        // change lines based on maximum lines and maximum words per line
        let tempLines = [];
        let tempLine = "";

        for (let i = 0; i < LINES_ARRAY.length; i++) {
            let line = LINES_ARRAY[i];
            if (!line || line.length == 0) {
                continue;
            }
            let words = line.split(" ");
            for (let j = 0; j < words.length; j++) {
                let word = words[j];
                let currentWordCount = tempLine.split(" ").filter(x => x.length > 0).length;

                if (currentWordCount + 1 <= maximumWordsPerLine) {
                    tempLine += word + " ";
                } else {
                    tempLines.push(tempLine.trim());
                    tempLine = word + " ";
                    if (tempLines.length >= maximumLines) {
                        break;
                    }
                }
            }
            if (tempLines.length >= maximumLines) {
                break;
            }
        }

        if (tempLine.length > 0 && tempLines.length < maximumLines) {
            tempLines.push(tempLine.trim());
        }

        if (tempLines.length > maximumLines) {
            tempLines = tempLines.slice(0, maximumLines);
        }
        setLines(tempLines);
    }, [maximumLines, maximumWordsPerLine, selectedTextStyle]);

    if (!("show_spoken_background" in selectedTextStyle)) {
        selectedTextStyle.show_spoken_background = true;
    }

    const dontShowCaptions = selectedTextStyle.name == "No Captions";

    return (
        <div className="bg-transparent rounded p-4"
            // @ts-ignore
             style={{
                 ...customStyles, // Spread the custom styles
             }}>
            <ScrollArea className="w-full whitespace-nowrap rounded-md border">
                <div className="flex w-max space-x-4 p-4">
                    {textStyles.map((style) => (
                        <div key={`${style.name}-${style.id}-${style.visible_name}`}
                             onClick={() => onTextStyleSelected(style)}
                             className={`cursor-pointer flex px-4 py-2 items-center justify-center flex-col flex-1 rounded shadow-xl ${(selectedTextStyle.id == style.id) ? "border rounded p-1 bg-white" : "bg-gray-100"}`}>
                            {style.visible_name || style.name}
                        </div>
                    ))}
                </div>
                <ScrollBar orientation="horizontal"/>
            </ScrollArea>
            <div className="overflow-hidden flex justify-center items-center m-4 relative notranslate" translate="no">
                <img width="300" height="534" className="z-0 rounded"
                     src={"https://storage.jetsnaps.ai/data/sample_images/text_style_background.png"}/>
                <div className="absolute z-[50] transform-y-1/2 justify-center" style={{top: top}}>
                    {!dontShowCaptions ? lines.map((line, line_index) => (
                        <div style={{zoom: 0.41, WebkitTextSizeAdjust: 'auto', textSizeAdjust: 'auto' }} key={line_index} className="flex justify-center items-center mx-2">
                            {line.split(" ").map((word, word_index) => (
                                <span key={word_index}
                                      data-text={word}
                                      style={{
                                          fontFamily: selectedTextStyle.font_family,
                                          fontSize: selectedTextStyle.font_size || "81px",
                                          textShadow: selectedTextStyle.show_neon_background ? word.localeCompare(selectedTextStyle.name) == 0 ? `0 0 10px ${neonSpokenGlowColor}, 0 0 20px ${neonSpokenGlowColor}, 0 0 30px ${neonSpokenGlowColor}, 0 0 40px ${neonSpokenGlowColor}` :  `0 0 10px ${neonGlowColor}, 0 0 20px ${neonGlowColor}, 0 0 30px ${neonGlowColor}, 0 0 40px ${neonGlowColor}` : '',
                                      }}
                                      className={`mx-2 uppercase ${line_index != 0 ? `mt-[-64px]` : ""} ${word == selectedTextStyle.name ? 'spoken-text' : 'outlined-text'}`}>
                                    <div>
                            {word.localeCompare(selectedTextStyle.name) == 0 && (selectedTextStyle.show_spoken_background) ?
                                <div className="background"/> : null
                            }
                            </div>
                                    {word}
                        </span>
                            ))}
                        </div>
                    )): null}
                </div>
            </div>
            <Tabs defaultValue="colors" className="mt-4">
                <TabsList className="bg-gray-200">
                    <TabsTrigger value="colors">Colors</TabsTrigger>
                    <TabsTrigger value="effects">Effects</TabsTrigger>
                </TabsList>
                <TabsContent value="colors">
                    <div className="flex flex-row gap-8 flex-wrap my-5">
                        <div className="flex justify-center items-center gap-2">
                            <input type="color" id="text_color" name="text_color" value={selectedTextStyle.text_color}
                                   className="h-14 w-14 border-none cursor-pointer"
                                   onChange={(e) => onTextStyleUpdated("text_color", e.target.value)}/>
                            <label htmlFor="text_color">Text Color</label>
                        </div>
                        <div className="flex justify-center items-center gap-2">
                            <input type="color" id="text_color" name="text_color"
                                   value={selectedTextStyle.text_stroke_color}
                                   className="h-14 w-14 border-none cursor-pointer"
                                   onChange={(e) => onTextStyleUpdated("text_stroke_color", e.target.value)}/>
                            <label htmlFor="text_color">Text Stroke Color</label>
                        </div>
                        <div className="flex justify-center items-center gap-2">
                            <input type="color" id="text_color" name="text_color"
                                   value={selectedTextStyle.highlighted_text_color}
                                   className="h-14 w-14 border-none cursor-pointer"
                                   onChange={(e) => onTextStyleUpdated("highlighted_text_color", e.target.value)}/>
                            <label htmlFor="text_color">Highlighted Text Color</label>
                        </div>
                        <div className="flex justify-center items-center gap-2">
                            <input type="color" id="text_color" name="text_color"
                                   value={selectedTextStyle.highlighted_text_stroke_color}
                                   className="h-14 w-14 border-none cursor-pointer"
                                   onChange={(e) => onTextStyleUpdated("highlighted_text_stroke_color", e.target.value)}/>
                            <label htmlFor="text_color">Highlighted Text Stroke Color</label>
                        </div>
                        <div className="flex justify-center items-center gap-2">
                            <input type="color" id="text_color" name="text_color"
                                   value={selectedTextStyle.popping_color_behind_text}
                                   className="h-14 w-14 border-none cursor-pointer"
                                   onChange={(e) => onTextStyleUpdated("popping_color_behind_text", e.target.value)}/>
                            <label htmlFor="text_color">Background</label>
                        </div>
                        <div className="flex items-center px-4 border border-gray-200 rounded">
                            <Checkbox checked={selectedTextStyle.show_spoken_background}
                                      onCheckedChange={(checked) => onTextStyleUpdated("show_spoken_background", checked)}/>
                            <label htmlFor="show_spoken_background"
                                   className="w-full py-4 ms-2 text-sm font-medium text-gray-900">Show Background</label>
                        </div>
                    </div>
                </TabsContent>
                <TabsContent value="effects">
                    <div className="my-5">
                        <div className="my-4">Text Stroke Width</div>
                        <Slider
                            defaultValue={[selectedTextStyle?.text_stroke_width ? parseInt(selectedTextStyle.text_stroke_width.toString().replace("px", "")) : 18]}
                            max={30}
                            step={1}
                            onValueChange={(value) => onTextStyleUpdated("text_stroke_width", `${value}px`)}/>
                    </div>
                    <div className="my-5">
                        <div className="my-4">Font size</div>
                        <Slider
                            defaultValue={[selectedTextStyle?.font_size ? parseInt(selectedTextStyle.font_size.toString().replace("px", "")) : 81]}
                            max={90}
                            step={1}
                            min={70}
                            onValueChange={(value) => onTextStyleUpdated("font_size", `${value}px`)}/>
                    </div>
                    <div className="my-5">
                        <div className="my-4">Text Layout</div>
                        <Slider
                            defaultValue={[selectedTextStyle?.text_margin_top_percent ? parseInt(selectedTextStyle.text_margin_top_percent.toString().replace("%", "")) : 40]}
                            max={70}
                            step={4}
                            min={10}
                            onValueChange={(value) => onTextStyleUpdated("text_margin_top_percent", `${value[0]}%`)}/>
                    </div>
                    <div className="my-5">
                        <div className="my-4">Maximum lines</div>
                        <Slider
                            defaultValue={[selectedTextStyle?.text_maximum_lines ? parseInt(selectedTextStyle.text_maximum_lines) : maximumLines]}
                            max={3}
                            step={1}
                            value={[selectedTextStyle?.text_maximum_lines ? parseInt(selectedTextStyle.text_maximum_lines) : maximumLines]}
                            className={"showStepBar"}
                            min={1}
                            onValueChange={(value) => onTextStyleUpdated("text_maximum_lines", `${value[0]}`)}/>
                    </div>
                    <div className="my-5">
                        <div className="my-4">Maximum words per line</div>
                        <Slider
                            defaultValue={[selectedTextStyle?.text_maximum_words_per_line ? parseInt(selectedTextStyle.text_maximum_words_per_line) : maximumWordsPerLine]}
                            max={3}
                            step={1}
                            value={[selectedTextStyle?.text_maximum_words_per_line ? parseInt(selectedTextStyle.text_maximum_words_per_line) : maximumWordsPerLine]}
                            className={"showStepBar"}
                            min={1}
                            onValueChange={(value) => onTextStyleUpdated("text_maximum_words_per_line", `${value[0]}`)}/>
                    </div>
                </TabsContent>
            </Tabs>
            <style>

            </style>
        </div>
    )
}

TextStyles.propTypes = {};

export default TextStyles
