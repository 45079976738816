import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {showJetToast} from "../Toast";

const AutomationCheckbox = ({automationEnabled, enableShortAutomationUrl}) => {
    const [isChecked, setChecked] = useState(automationEnabled || false);
    console.log(isChecked);

    const handleAutomationChange = async () => {
        try {
            const response = await fetch(enableShortAutomationUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({auto_post: !isChecked}),
            })
            const automationResponse = await response.json();
            if (automationResponse.error) {
                throw new Error(automationResponse.error);
            }
            console.log(automationResponse);
            if (automationResponse.success) {
                showJetToast(automationResponse.success);
            } else {
                showJetToast("Automation settings updated successfully");
            }
        } catch (error) {
            showJetToast("Failed to update automation settings");
        }
        setChecked(!isChecked);

    }

    useEffect(() => {

    }, [isChecked]);

    return (
        <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" checked={isChecked} onChange={handleAutomationChange} name="automation"
                   id="automation-checkbox" value="1" className="sr-only peer"/>
            <div
                className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-primary rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all peer-checked:bg-primary"></div>
            <span className="ms-3 text-sm font-medium text-gray-900">Automation</span>
        </label>
    )
}

AutomationCheckbox.propTypes = {
    automationEnabled: PropTypes.bool.isRequired,
    enableShortAutomationUrl: PropTypes.string.isRequired
};

export default AutomationCheckbox
