import React from "react"
import PropTypes from "prop-types"
import Player from "../common/Player";

const AudioStyles = ({audioStyles, onAudioStyleSelected, selectedAudioStyle}) => {
    return (
        <div className="flex gap-2 flex-wrap">
            {audioStyles.map((style) => (
                <div key={style.name}
                     className={`w-1/2 lg:w-1/4 p-4 bg-white cursor-pointer shadow-md rounded text-center ${selectedAudioStyle?.id == style.id ? "border" : ""}`}
                     onClick={() => onAudioStyleSelected(style)}>
                    <div>
                        {style.name}
                    </div>
                    <div className="overflow-hidden rounded-md">
                        <Player src={style.audio} />
                    </div>
                </div>
            ))}
        </div>
    )
}

AudioStyles.propTypes = {
    audioStyles: PropTypes.array.isRequired,
    onAudioStyleSelected: PropTypes.func.isRequired
};

export default AudioStyles
