import React from "react";
import PropTypes from "prop-types";

const YoutubeAccount = ({account, handleAccountClick, selected}) => {

    return (
        <div onClick={() => handleAccountClick(account.id)}
             className={`${selected ? "border border-1" : ""} cursor-pointer flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-md`}>
            <img src={account["snippet"]["thumbnails"]["medium"]["url"]} alt={account["snippet"]["title"]}
                 className="w-20 h-20 rounded-full mb-2"/>
            <div className="text-center">
                <span className="text-lg font-semibold">{account["snippet"]["customUrl"]}</span>
                <p className="text-sm text-gray-500">{account["snippet"]["title"]}</p>
                <p className="text-sm text-gray-500">Channel ID: {account["id"]}</p>
            </div>
        </div>
    );
}

YoutubeAccount.propTypes = {
    account: PropTypes.object,
    handleAccountClick: PropTypes.func,
    selected: PropTypes.bool
};

export default YoutubeAccount;