import React, {useEffect, useState} from "react"
import textStylesData from "./text_styles.json";
import TextStyles from "./TextStyles";

const TestTextStyles = () => {
    const [selectedTextStyle, setSelectedTextStyle] = useState(textStylesData[0]);

    return (
        <div className="w-full m-4">
            <TextStyles
                textStyles={textStylesData}
                selectedTextStyle={selectedTextStyle}
                onTextStyleSelected={setSelectedTextStyle}
            />
        </div>
    )
}

TestTextStyles.propTypes = {

};

export default TestTextStyles